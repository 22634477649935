interface StartOfDayInterface {
  startOfDay: Date | null | undefined;
  hoursPerStep: number | null;
}

/**
 * Checks if the given `startOfDay` variable's current hours are greater
 *  than or equal to 22, and if the hours / step is equal to 24.
 *
 * This is to done to ensure that the daylight savings helper only operates
 * on the 24hr time slider layers, and not the 1hr or 3hr timesliders.
 * @returns `True` if the above is the case, otherwise returns `False`
 */
function startOfDayHelper({ startOfDay, hoursPerStep }: StartOfDayInterface) {
  return startOfDay && startOfDay.getHours() >= 23 && hoursPerStep && hoursPerStep === 24;
}

export default startOfDayHelper;
