/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useRef } from 'react';

import { useNavigate } from 'react-router-dom';
import { isFirefox } from 'react-device-detect';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Theme, Drawer, Toolbar, Button, CircularProgress, TextField } from '@material-ui/core';
import {
  ChevronLeft,
  ChevronRight,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Compare,
  LocationSearching,
} from '@material-ui/icons';

import {
  Map,
  FloatingMapButton,
  MapOverlay,
  BookmarkedLayers,
  LayerList,
  IncidentsManager,
  ExportManager,
  IncidentPopup,
  Timeslider,
  WeatherPopup,
  WeatherStationPopup,
} from 'components';
import Legend from 'components/map/Legend';

import OpacitySlider from 'components/map/OpacitySlider';
import {
  MapCommand,
  ZoomToBounds,
  ZoomToCoords,
  AddMarkers,
  Resize,
  AddScaleLine,
  MouseCoords,
  AddWmsTileLayer,
  AddWmsMvtLayer,
  ShowOverlay,
  HideOverlay,
  MouseClick,
  GetMapBounds,
} from 'components/map/MapCommands';
import { getUserState, hasGroup } from 'utils';

import { useAppSelector, useAppDispatch } from 'hooks';
import { LayerActions } from 'state/layers';
import { IncidentActions } from 'state/incidents';
import { MeteogramActions } from 'state/meteograms';
import { FuelTypeModelActions } from 'state/fueltypemodels';
import { AnalyticsActions } from 'state/analytics';

import { IncidentManager, LayerManager, MeteogramManager, WmsManager } from 'models';
import config from 'config';
import DetailsPanel from 'components/detailspanel/Panel';
import { toLonLat } from 'ol/proj';
import { Style, Icon } from 'ol/style';

const initOpacity = 65;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    map: {
      width: '100%',
      height: '100%',
    },
    drawer: {
      flexShrink: 0,
    },
    drawerPaper: {},
    drawerContent: {
      padding: theme.spacing(0),
      display: 'grid',
      gridTemplateRows: '1fr auto',
      overflowY: 'auto',
      height: '100%',
    },
    drawerButton: {
      margin: `${theme.spacing(0.25)}px ${theme.spacing(1)}px`,
    },
    title: {
      fontWeight: 'bold',
      marginTop: theme.spacing(7),
      marginBottom: theme.spacing(5),
      color: theme.palette.text.primary,
    },
    subtitle: {
      fontWeight: 'bold',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
      color: theme.palette.common.neutralDark, // neutralDark
    },
    sidebarfab: {
      color: theme.palette.common.grey,
      transform: 'rotate(90deg)',
      width: 'max-content',
      marginTop: 50,
    },
    sidebarfabicon: {
      color: theme.palette.common.grey,
    },
    sidebarBtn: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      border: `1px solid ${theme.palette.common.neutralLight}`,
      marginBottom: 2,
      borderRadius: 8,
      color: theme.palette.common.black70,
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.common.neutralXLight,
      },
    },
    sidebarBtnLabel: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
    },
    timesliderfab: {
      color: theme.palette.common.grey,
      width: 'max-content',
    },
    link: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: `${theme.spacing(1)}px 0px`,
      padding: theme.spacing(2),
      border: `1px solid ${theme.palette.common.neutralXLight}`,
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.common.neutralXLight,
        cursor: 'pointer',
      },
    },
    linkTitle: {
      fontWeight: 'bold',
    },
    linkSubtitle: {
      fontWeight: 'bold',
    },
    linkArrow: {
      color: theme.palette.common.neutral,
    },
    content: {
      flexGrow: 1,
    },
    bookmarkArea: {
      overflowY: 'auto',
      borderBottom: '1px solid lightgrey',
      padding: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    drawerTransition: {
      transition: `${theme.transitions.create('left', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      })}, ${theme.transitions.create('height', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      })}, ${theme.transitions.create('width', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      })}, ${theme.transitions.create('top', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      })}, ${theme.transitions.create('transform', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      })} !important`,
    },
    drawerTransitionShift: {
      transition: `${theme.transitions.create('left', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      })}, ${theme.transitions.create('height', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      })}, ${theme.transitions.create('width', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      })}, ${theme.transitions.create('top', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      })}, ${theme.transitions.create('transform', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      })} !important`,
    },
    panel: {
      overflow: 'hidden',
      transition: `${theme.transitions.create('top', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      })}`,
    },
    compareFAB: {
      borderRadius: 4,
      filter: `drop-shadow(0px 2px 4px ${theme.palette.common.neutral})`,
    },
    lonlat: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr auto',
      placeItems: 'center',
      padding: `0px ${theme.spacing(1)}px ${theme.spacing(0.5)}px`,
    },
  }),
);

function FDVViewer() {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const mapRef = useRef<any>(null);

  const { auth, incidents, meteograms, fuelTypeModels } = useAppSelector((state) => state);
  const { layers, selectedBoundary, selectedMainLayer, selectedLayers, selectedBaseMap } = useAppSelector(
    (state) => state.layers,
  );

  const [mapDispatch, setMapDispatch] = useState<{ dispatch: (command: MapCommand) => void }>();
  const [boundaryLayerCommand, setBoundaryLayerCommand] = useState<AddWmsMvtLayer>();
  const [incidentsLayerCommand, setIncidentsLayerCommand] = useState<AddWmsTileLayer>();
  const [weatherStationsLayerCommand, setWeatherStationsLayerCommand] = useState<AddWmsTileLayer>();
  const [rasterLayerCommand, setRasterLayerCommand] = useState<AddWmsTileLayer>();
  const [childLayerCommand, setChildLayerCommand] = useState<AddWmsTileLayer>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [mouseClick, setMouseClick] = useState<MouseClick>();
  const [mapBounds, setMapBounds] = useState<GetMapBounds>();

  const [sentInitialLoad, setSentInitialLoad] = useState(false);

  const selectedRasterLayer = layers.object?.find((l) => l.id === selectedMainLayer);

  const selectedRasterLayerList = selectedRasterLayer ? [selectedRasterLayer] : [];

  const childLayer =
    selectedRasterLayer && selectedRasterLayer.childLayer
      ? layers.object?.find((l) => l.id === selectedRasterLayer.childLayer)
      : null;

  if (childLayer) selectedRasterLayerList.push(childLayer);

  const [goToIncident, setGoToIncident] = useState<string | null>(null);
  const [goToLatitude, setGoToLatitude] = useState<string>('');
  const [goToLongitude, setGoToLongitude] = useState<string>('');

  const [drawerOpen, setDrawerOpen] = useState(true);
  const [drawerWidthValue, setDrawerWidth] = useState(16.667);
  const drawerWidth = `${drawerWidthValue}%`;
  const [drawerLeftOffsetValue, setDrawerLeftOffset] = useState(0);
  const drawerLeftOffset = `${drawerLeftOffsetValue}%`;

  const [drawerOuterOpen, setDrawerOuterOpen] = useState(false);
  const [drawerOuterWidthValue, setDrawerOuterWidth] = useState(16.667);
  const drawerOuterWidth = `${drawerOuterWidthValue}%`;

  const [panelOpen, setPanelOpen] = useState(false);
  const [clickCoords, setClickCoords] = useState<number[] | null>(null);
  const [clickDetails, setClickDetails] = useState<Record<string, number | string> | null>(null);

  const [selectedWeatherStation, setselectedWeatherStation] = useState<number | null>(null);

  const [timeSliderOpen, setTimeSliderOpen] = useState(selectedRasterLayer?.isTimeEnabled ?? true);
  const [timeSliderTime, setTimeSliderTime] = useState<null | Date>(null);
  const [timeSliderLayerDates, setTimeSliderLayerDates] = useState<Record<string, Date | null>>({});

  const mapOffset = `${drawerLeftOffsetValue + drawerWidthValue}%`;

  const styleMarker = () =>
    new Style({
      image: new Icon({
        anchor: [0.5, 1],
        src: '/map/pin-red.png',
      }),
    });

  const goToLocation = () => {
    if (goToLatitude && goToLongitude && !(Number.isNaN(+goToLatitude) || Number.isNaN(+goToLongitude)))
      mapDispatch?.dispatch(new ZoomToCoords([+goToLongitude, +goToLatitude]));
    const coords = [+goToLongitude, +goToLatitude];
    let markers = [{ coords, id: 23456, meta: '' }];
    if (coords[0] === 0 && coords[1] === 0) markers = [];
    mapDispatch?.dispatch(new AddMarkers(markers, undefined, styleMarker));
  };

  const makeOnMouseClick = (mapDispatcher: (command: MapCommand) => void) => (coords: number[], event: any) => {
    const markers = [{ coords, id: 12345, meta: '' }];

    mapDispatcher(new AddMarkers(markers, undefined, styleMarker));
    mapDispatcher(new ShowOverlay('loading', coords));
    mapDispatcher(new HideOverlay('weather'));
    mapDispatcher(new HideOverlay('weatherstation'));
    mapDispatcher(new HideOverlay('incident'));

    const e = event.map.getView().calculateExtent();
    const extent = [...toLonLat([e[0], e[1]]), ...toLonLat([e[2], e[3]])];
    const offsetLon = (extent[3] - extent[1]) / 40;
    const offsetLat = (extent[3] - extent[1]) / 40;
    const bbox = {
      minLat: coords[1] - offsetLat,
      minLong: coords[0] - offsetLon,
      maxLat: coords[1] + offsetLat,
      maxLong: coords[0] + offsetLon,
    };

    // If incidents layer is selected, then send query
    // otherwise replace promise with a promise that returns an empty list
    const identifyIncidentsPromise =
      selectedLayers && selectedLayers.indexOf('Incidents') > -1
        ? dispatch(
            IncidentActions.getIncidentsByBBox({
              bbox,
            }),
          )
        : new Promise<IncidentManager.Incident[]>((resolve) => resolve([]));

    // If weather stations layer is selected, then send query
    // otherwise replace promise with a promise that returns an empty list
    const identifyWeatherStationsPromise =
      selectedLayers && selectedLayers.indexOf('WeatherStations') > -1
        ? dispatch(
            MeteogramActions.getWeatherStationsByBBox({
              bbox,
            }),
          )
        : new Promise<MeteogramManager.Meteograms.WeatherStation[]>((resolve) => resolve([]));

    const fireWeatherAreas = LayerManager.findLayer('FireWeatherAreas', layers.object);
    const identifyFireWeatherAreaPromise = WmsManager.queryWMS(
      fireWeatherAreas ? [fireWeatherAreas] : [],
      coords,
      auth.object,
      undefined,
    );

    const listOfLayerIdsToLoad: LayerManager.Layer.LayerIds[] = [
      'IDZ71000_AUS_T_SFC',
      'IDZ71018_AUS_RH_SFC',
      'IDZ71071_AUS_WindMagKmh_SFC',
      'IDZ71127_AUS_DF_SFC',
      'IDZ71014_AUS_DailyPrecip25Pct_SFC',
      'IDZ71016_AUS_DailyPrecip75Pct_SFC',
      'IDZ10161_AUS_FSE_fuel_type_SFC',
      'IDZ10134_AUS_AFDRS_fdr_SFC',
      'IDZ10136_AUS_AFDRS_max_fdr_SFC',
    ];
    if (selectedRasterLayer && listOfLayerIdsToLoad.indexOf(selectedRasterLayer.id) === -1)
      listOfLayerIdsToLoad.push(selectedRasterLayer.id);

    const layersToQuery = layers.object
      ? (listOfLayerIdsToLoad
          .map((id) => LayerManager.findLayer(id, layers.object))
          .filter((x) => x != null) as LayerManager.Layer[])
      : [];

    const layerPromises: Promise<Record<string, number>>[] = [];

    layersToQuery.forEach((l) => {
      // getStepTime will return the time already corrected for timezones and timesteps
      const layerSelectedTime: Date | undefined =
        LayerManager.getStepTime(layers.object ?? [], l.id, timeSliderTime) ?? undefined;

      layerPromises.push(
        WmsManager.queryWMSValues(
          [l],
          coords,
          auth.object,
          layerSelectedTime,
          l.lastUpdated != null ? new Date(l.lastUpdated) : undefined,
        ),
      );
    });

    Promise.all([identifyIncidentsPromise, identifyWeatherStationsPromise, identifyFireWeatherAreaPromise]).then(
      ([identifyIncidents, identifyWeatherStations, identifyFireWeatherArea]) => {
        Promise.all(layerPromises).then((otherValues) => {
          mapDispatcher(new HideOverlay('loading'));
          if (identifyIncidents.length > 0) {
            mapDispatcher(new ShowOverlay('incident', coords));
          } else {
            mapDispatcher(new HideOverlay('incident'));
            setGoToIncident('');

            if (identifyWeatherStations.length > 0) {
              setselectedWeatherStation(identifyWeatherStations[0].id);
              mapDispatcher(new ShowOverlay('weatherstation', coords));
            } else {
              setselectedWeatherStation(null);
              mapDispatcher(new ShowOverlay('weather', coords));
            }

            const details = otherValues.reduce<Record<string, number | string>>((acc, val) => {
              return { ...acc, ...val };
            }, {});

            if (identifyFireWeatherArea?.features[0]?.properties.fire_area_name) {
              details.FireWeatherAreas = identifyFireWeatherArea.features[0].properties.fire_area_name;
              const jurisdiction = /^([A-Z]+)_[a-z1-9]*/.exec(identifyFireWeatherArea.features[0].properties.aac)?.[1];
              if (jurisdiction) details.Jurisdiction = jurisdiction;
            }

            setClickCoords(coords);
            setClickDetails(details);
          }
        });
      },
    );
  };

  const hideIncidentOverlay = () => {
    mapDispatch?.dispatch(new HideOverlay('incident'));
    mapDispatch?.dispatch(new AddMarkers([], undefined, styleMarker));
    setGoToIncident('');
  };

  const handleMapRegistration = (mapDispatcher: (command: MapCommand) => void) => {
    setMapDispatch({ dispatch: mapDispatcher });
    mapDispatcher(new AddScaleLine());
    mapDispatcher(new MouseCoords());

    const mb = new GetMapBounds();
    setMapBounds(mb);
    mapDispatcher(mb);

    const mc = new MouseClick(makeOnMouseClick(mapDispatcher));
    setMouseClick(mc);
    mapDispatcher(mc);

    const rasterLayer = new AddWmsTileLayer({ layerName: null, auth: auth.object, opacity: initOpacity });
    setRasterLayerCommand(rasterLayer);
    mapDispatcher(rasterLayer);

    const childRasterLayer = new AddWmsTileLayer({ layerName: null, auth: auth.object, opacity: initOpacity });
    setChildLayerCommand(childRasterLayer);
    mapDispatcher(childRasterLayer);

    const boundaryLayer = new AddWmsMvtLayer({ layerName: null, labelField: null, auth: auth.object });
    setBoundaryLayerCommand(boundaryLayer);
    mapDispatcher(boundaryLayer);

    const weatherStationLayer = new AddWmsTileLayer({
      layerName:
        selectedLayers && selectedLayers.indexOf('WeatherStations') > -1
          ? LayerManager.findLayer('WeatherStations', LayerManager.layerData)?.serviceName ?? null
          : null,
      auth: auth.object,
    });
    setWeatherStationsLayerCommand(weatherStationLayer);
    mapDispatcher(weatherStationLayer);

    const incidentsLayer = new AddWmsTileLayer({
      layerName:
        selectedLayers && selectedLayers.indexOf('Incidents') > -1
          ? LayerManager.findLayer('Incidents', LayerManager.layerData)?.serviceName ?? null
          : null,
      auth: auth.object,
    });
    setIncidentsLayerCommand(incidentsLayer);
    mapDispatcher(incidentsLayer);
  };

  const handleTimeChange = (date: Date | null | undefined, layerDates: Record<string, Date | null>) => {
    if (selectedRasterLayer?.hoursPerStep === 0) {
      setTimeSliderTime(null);
      setTimeSliderLayerDates({});
    } else if (selectedRasterLayer) {
      setTimeSliderTime(date ?? null);
      setTimeSliderLayerDates(layerDates);
    }

    if (selectedRasterLayer && layerDates[selectedRasterLayer.id]) {
      rasterLayerCommand?.updateDate(
        layerDates[selectedRasterLayer.id],
        selectedRasterLayer.lastUpdated != null ? new Date(selectedRasterLayer.lastUpdated) : null,
      );

      if (childLayer) {
        childLayerCommand?.updateDate(
          layerDates[childLayer.id],
          childLayer.lastUpdated != null ? new Date(childLayer.lastUpdated) : null,
        );
      }
    } else if (selectedRasterLayer?.hoursPerStep === 0) {
      rasterLayerCommand?.updateDate(
        selectedRasterLayer.timeSteps?.[0],
        selectedRasterLayer.lastUpdated != null ? new Date(selectedRasterLayer.lastUpdated) : null,
      );

      if (childLayer?.hoursPerStep === 0) {
        childLayerCommand?.updateDate(
          childLayer.timeSteps?.[0],
          childLayer.lastUpdated != null ? new Date(childLayer.lastUpdated) : null,
        );
      }
    }
  };

  const onOpacityUpdate = (value: number) => {
    rasterLayerCommand?.setOpacity(value);

    if (childLayer) childLayerCommand?.setOpacity(value);
  };

  useEffect(() => {
    if (auth.status === 'finished' && fuelTypeModels.all.status === 'idle')
      dispatch(FuelTypeModelActions.getFuelTypeModelsAll());
  }, [auth.status]);

  useEffect(() => {
    if (mouseClick && mapDispatch) {
      mouseClick.update(makeOnMouseClick(mapDispatch.dispatch));
      // Hide the weather layer so it prevents showing the incorrect data
      // An alterative is to call mouseClick.rerunLastClick();
      // but that may cause some performance issues when dragging the slider
      mapDispatch.dispatch(new HideOverlay('weather'));
    }
  }, [timeSliderTime, timeSliderLayerDates, layers.status, selectedLayers, selectedRasterLayer, auth.object]);

  useEffect(() => {
    if (auth.status === 'finished') {
      const jd = getUserState(auth.object);
      if (jd) mapDispatch?.dispatch(new ZoomToBounds(config.jurisdictionBounds[jd]));
    }
  }, [auth.status, mapDispatch]);

  useEffect(() => {
    if (layers.status === 'idle' && auth.status === 'finished') dispatch(LayerActions.getLayers());

    if (!sentInitialLoad && layers.status === 'finished' && auth.status === 'finished') {
      selectedLayers?.forEach((id) => {
        const l = LayerManager.findLayer(id, layers.object);
        if (l) dispatch(AnalyticsActions.postLayerView(l));
      });

      if (selectedRasterLayer) dispatch(AnalyticsActions.postLayerView(selectedRasterLayer));

      if (childLayer) dispatch(AnalyticsActions.postLayerView(childLayer));

      if (selectedBoundary) {
        const l = LayerManager.findLayer(selectedBoundary, layers.object);
        if (l) dispatch(AnalyticsActions.postLayerView(l));
      }

      if (selectedBaseMap) {
        const l = LayerManager.findLayer(selectedBaseMap, layers.object);
        if (l) dispatch(AnalyticsActions.postLayerView(l));
      }

      setSentInitialLoad(true);
    }

    // regularly update layers every 5 minutes
    const interval = setInterval(() => {
      if ((layers.status === 'idle' || layers.status === 'finished') && auth.status === 'finished')
        dispatch(LayerActions.getLayers());
    }, 5 * 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [dispatch, layers.status, auth.status]);

  useEffect(() => {
    const selectedBoundaryLayer = layers.object?.find((l) => l.id === selectedBoundary);
    boundaryLayerCommand?.update({
      layerName: selectedBoundaryLayer != null ? selectedBoundaryLayer.serviceName : null,
      labelField: selectedBoundaryLayer != null ? selectedBoundaryLayer.mvtLabelField : null,
      auth: auth.object,
    });
  }, [selectedBoundary, boundaryLayerCommand, layers.object, auth.object]);

  useEffect(() => {
    // Use the current timeslider time to initialize or if that is null, then the first timestep
    // named latest start to match with the compare tool
    const latestStart = LayerManager.getLatestLayerStartDate([selectedRasterLayer]);
    const selectedLayer = layers.object?.find((l) => l.id === selectedMainLayer);

    // If there is a selected layer, get the most relevant time step
    let stepTime = null;
    if (selectedLayer?.id) {
      stepTime = LayerManager.getStepTime(layers.object || undefined, selectedLayer?.id, timeSliderTime ?? latestStart);
    }

    rasterLayerCommand?.update({
      layerName: selectedLayer != null ? selectedLayer.serviceName : null,
      auth: auth.object,
      date: stepTime != null ? stepTime : null,
    });

    if (selectedLayer?.childLayer) {
      const selectedChildLayer = layers.object?.find((l) => l.id === selectedLayer.childLayer);
      childLayerCommand?.update({
        layerName: selectedChildLayer != null ? selectedChildLayer.serviceName : null,
        auth: auth.object,
        date: stepTime != null ? stepTime : null,
      });
    } else {
      childLayerCommand?.update({
        layerName: null,
        auth: auth.object,
      });
    }
    if (selectedLayer?.hoursPerStep === 0) {
      handleTimeChange(null, {} as Record<string, Date | null>);
    }
  }, [selectedMainLayer, rasterLayerCommand, childLayerCommand, layers.object, auth.object]);

  useEffect(() => {
    if (selectedLayers && selectedLayers.indexOf('Incidents') > -1) {
      incidentsLayerCommand?.update({
        layerName: LayerManager.findLayer('Incidents', LayerManager.layerData)?.serviceName ?? null,
        auth: auth.object,
      });
    } else {
      incidentsLayerCommand?.update({
        layerName: null,
        auth: auth.object,
      });
    }

    if (selectedLayers && selectedLayers.indexOf('WeatherStations') > -1) {
      weatherStationsLayerCommand?.update({
        layerName: LayerManager.findLayer('WeatherStations', LayerManager.layerData)?.serviceName ?? null,
        auth: auth.object,
      });
    } else {
      weatherStationsLayerCommand?.update({
        layerName: null,
        auth: auth.object,
      });
    }
  }, [selectedLayers, auth.object]);

  const refreshIncidents = () => incidentsLayerCommand?.refresh();

  useEffect(() => {
    // will update incidents every 60 seconds
    const interval = setInterval(() => refreshIncidents(), 60000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incidentsLayerCommand]);

  const updateDrawer = (value: boolean, width?: number) => {
    setDrawerOpen(value);
    // if (!value) setDrawerOuterOpen(false);
    if (width) setDrawerWidth(width);

    if (mapDispatch) {
      setTimeout(() => mapDispatch.dispatch(new Resize()), 5);
    }
  };

  const updateTimeSlider = (value: boolean) => {
    setTimeSliderOpen(value);

    if (mapDispatch) {
      setTimeout(() => mapDispatch.dispatch(new Resize()), 5);
    }
  };

  const updatePanel = (display: boolean) => {
    setPanelOpen(display);
    if (mapDispatch) {
      setTimeout(() => mapDispatch.dispatch(new Resize()), 5);
    }
  };

  const updateOuterDrawer = (value: boolean, width?: number) => {
    setDrawerOuterOpen(value);
    if (width) setDrawerOuterWidth(width);

    if (value && drawerOuterOpen && width) {
      setDrawerOuterOpen(false);
      setDrawerLeftOffset(0);
      setTimeout(() => {
        setDrawerLeftOffset(width);
        setDrawerOuterOpen(value);
      }, 250);
    } else if (value && width) {
      setDrawerLeftOffset(width);
    } else {
      setDrawerLeftOffset(0);
    }

    if (mapDispatch) {
      setTimeout(() => mapDispatch.dispatch(new Resize()), 5);
    }
  };

  const tabs = {
    mainlayers: {
      width: isFirefox ? 16.667 : 33.334,
      html: (
        <LayerList
          title="Base Layers"
          columns={isFirefox ? 1 : 2}
          filterFn={(l) =>
            !l.isBaseMap &&
            !l.isBoundary &&
            !l.isHidden &&
            (!l.isPrivileged || (l.isPrivileged && hasGroup('fdv-elevated', auth)))
          }
        />
      ),
    },
    basemaps: {
      width: 16.667,
      html: <LayerList title="Basemaps" filterFn={(l) => l.isBaseMap} />,
    },
    boundary: {
      width: 16.667,
      html: <LayerList title="Boundaries" filterFn={(l) => l.isBoundary} />,
    },
    incidents: {
      width: 28,
      html: (
        <IncidentsManager
          onRefresh={refreshIncidents}
          goToIncident={goToIncident}
          onZoomToIncident={(incident) => mapDispatch?.dispatch(new ZoomToBounds(incident.bbox, { maxZoom: 16 }))}
        />
      ),
    },
    export: {
      width: 16.667,
      html: <ExportManager mapRef={mapRef} selectedDate={timeSliderTime} />,
    },
  };

  const [tabId, setTabId] = useState<keyof typeof tabs | null>(null);

  const loadTab = (id: typeof tabId) => {
    if (tabId === id) {
      setTabId(null);
      updateOuterDrawer(false);
    } else {
      setTabId(id);
      updateOuterDrawer(id !== null, id ? tabs[id].width : undefined);
    }
  };

  return (
    <>
      <Drawer
        variant="persistent"
        anchor="left"
        open={drawerOuterOpen && drawerOpen}
        onClose={() => updateDrawer(false)}
        className={`${classes.drawer} ${classes.content} ${classes.drawerTransition} ${
          drawerOuterOpen ? classes.drawerTransitionShift : ''
        }`}
        style={{
          width: drawerOuterWidth,
        }}
        PaperProps={{
          style: {
            width: drawerOuterWidth,
          },
        }}
        classes={{
          paper: `${classes.drawerTransition} ${drawerOpen ? classes.drawerTransitionShift : ''}`,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContent}>{tabId != null && tabs[tabId].html}</div>
      </Drawer>
      <Drawer
        variant="persistent"
        anchor="left"
        open={drawerOpen}
        onClose={() => updateDrawer(false)}
        className={`${classes.drawer} ${classes.content} ${classes.drawerTransition} ${
          drawerOpen ? classes.drawerTransitionShift : ''
        }`}
        style={{
          width: drawerWidth,
        }}
        PaperProps={{
          style: {
            width: drawerWidth,
            left: drawerLeftOffset,
          },
        }}
        classes={{
          paper: `${classes.drawerTransition} ${drawerOpen ? classes.drawerTransitionShift : ''}`,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContent}>
          <div className={classes.bookmarkArea}>
            <BookmarkedLayers />
          </div>
          <div className={classes.lonlat}>
            <TextField
              value={goToLongitude}
              type="number"
              size="small"
              onChange={(event: any) => setGoToLongitude(event?.target?.value)}
              variant="outlined"
              placeholder="Longitude"
              InputProps={{
                inputProps: {
                  style: {
                    padding: theme.spacing(0.5),
                  },
                },
              }}
            />
            <TextField
              value={goToLatitude}
              type="number"
              size="small"
              onChange={(event: any) => setGoToLatitude(event?.target?.value)}
              variant="outlined"
              placeholder="Latitude"
              InputProps={{
                inputProps: {
                  style: {
                    padding: theme.spacing(0.5),
                  },
                },
              }}
            />
            <LocationSearching style={{ cursor: 'pointer' }} onClick={goToLocation} />
          </div>
          <Button
            className={classes.sidebarBtn}
            classes={{ label: classes.sidebarBtnLabel }}
            onClick={() => loadTab('mainlayers')}
          >
            {tabId === 'mainlayers' ? <ChevronLeft /> : <ChevronRight />}
            All Layers
          </Button>
          <Button
            className={classes.sidebarBtn}
            classes={{ label: classes.sidebarBtnLabel }}
            onClick={() => loadTab('basemaps')}
          >
            {tabId === 'basemaps' ? <ChevronLeft /> : <ChevronRight />}
            Base Maps
          </Button>
          <Button
            className={classes.sidebarBtn}
            classes={{ label: classes.sidebarBtnLabel }}
            onClick={() => loadTab('boundary')}
          >
            {tabId === 'boundary' ? <ChevronLeft /> : <ChevronRight />}
            Boundaries
          </Button>
          <Button
            className={classes.sidebarBtn}
            classes={{ label: classes.sidebarBtnLabel }}
            onClick={() => {
              loadTab('incidents');
              setGoToIncident('');
            }}
          >
            {tabId === 'incidents' ? <ChevronLeft /> : <ChevronRight />}
            Incidents
          </Button>
          <Button
            className={classes.sidebarBtn}
            classes={{ label: classes.sidebarBtnLabel }}
            onClick={() => loadTab('export')}
          >
            {tabId === 'export' ? <ChevronLeft /> : <ChevronRight />}
            Export
          </Button>
        </div>
      </Drawer>
      <div
        className={`${classes.map}`}
        style={{
          width: drawerOpen ? `calc(100% - ${drawerWidth} - ${drawerLeftOffset})` : '100%',
          marginLeft: drawerOpen ? mapOffset : '0',
        }}
      >
        <div style={{ display: 'grid', height: '100%', gridTemplateRows: '1fr auto auto' }}>
          <div style={{ flexGrow: 1, position: 'relative' }}>
            <Map
              mapRef={mapRef}
              basemap={selectedBaseMap}
              shouldDisplay
              registerMapCommand={handleMapRegistration}
              customOverlays={[
                {
                  id: 'loading',
                  jsx: (
                    <div
                      style={{
                        width: 10,
                        height: 10,
                        transform: 'translate(-5px, -5px)',
                        backgroundColor: 'transparent',
                        display: 'grid',
                      }}
                    >
                      <CircularProgress style={{ color: 'black' }} size="10" aria-valuetext="loading" />
                    </div>
                  ),
                },
                {
                  id: 'incident',
                  jsx: (
                    <IncidentPopup
                      close={hideIncidentOverlay}
                      incidents={incidents.identify}
                      onViewDetails={(i: any) => {
                        if (tabId !== 'incidents') loadTab('incidents');
                        // Id on it's own doesn't seem to work
                        setGoToIncident(i.title ?? i.id);
                      }}
                    />
                  ),
                },
                {
                  id: 'weatherstation',
                  jsx: (
                    <WeatherStationPopup
                      close={() => mapDispatch?.dispatch(new HideOverlay('weatherstation'))}
                      weatherStations={meteograms.identify}
                      onViewDetails={(weatherStation) => {
                        setselectedWeatherStation(weatherStation.id);
                        setTimeSliderOpen(false);
                        setPanelOpen(true);
                      }}
                    />
                  ),
                },
                {
                  id: 'weather',
                  jsx: (
                    <WeatherPopup
                      close={() => {
                        mapDispatch?.dispatch(new HideOverlay('weather'));
                        mapDispatch?.dispatch(new AddMarkers([], undefined, styleMarker));
                      }}
                      onViewDetails={() => {
                        setTimeSliderOpen(false);
                        setPanelOpen(true);
                      }}
                      selectedDate={timeSliderTime}
                      selectedLayer={selectedRasterLayer}
                      coords={clickCoords}
                      data={clickDetails}
                    />
                  ),
                },
              ]}
            >
              <FloatingMapButton
                left="65px"
                top="20px"
                width="120px"
                height="30px"
                className={classes.compareFAB}
                buttonStyle={{
                  color: theme.palette.common.neutralDark,
                }}
                onClick={async () => {
                  await dispatch(LayerActions.setViewBounds(mapBounds?.getBounds() ?? null));
                  navigate('/compare');
                }}
                muiButton
              >
                <Compare /> Compare
              </FloatingMapButton>
              <MapOverlay
                top="20px"
                left="205px"
                opacity={1.0}
                style={{
                  overflowX: 'auto',
                  whiteSpace: 'nowrap',
                  filter: `drop-shadow(0px 2px 4px ${theme.palette.common.neutral})`,
                }}
              >
                <OpacitySlider onChange={onOpacityUpdate} initialValue={initOpacity} />
              </MapOverlay>
              <FloatingMapButton
                ariaLabel="toggle mapview config"
                onClick={() => updateDrawer(!drawerOpen)}
                style={{ borderRadius: '0 4px 4px 0' }}
                buttonStyle={{ flexDirection: 'column', justifyContent: 'stretch' }}
                width="25px"
                height={drawerOpen ? '25px' : '150px'}
                top={`calc(50% - ${drawerOpen ? '12.5px' : '75px'})`}
                left="0%"
                className={`${classes.content} ${classes.drawerTransition} ${
                  drawerOpen ? classes.drawerTransitionShift : ''
                }`}
              >
                {drawerOpen ? (
                  <ChevronLeft className={classes.sidebarfabicon} />
                ) : (
                  <>
                    <ChevronRight className={classes.sidebarfabicon} />
                    <div className={classes.sidebarfab}>Show side panel</div>
                  </>
                )}
              </FloatingMapButton>
              <FloatingMapButton
                ariaLabel="toggle time slider draw"
                onClick={() => updateTimeSlider(!timeSliderOpen)}
                style={{ borderRadius: '0 4px 4px 0' }}
                buttonStyle={{ flexDirection: 'row' }}
                width={timeSliderOpen ? '25px' : '250px'}
                height="25px"
                top="calc(100% - 25px)"
                left={`calc(50% - ${timeSliderOpen ? '12.5px' : '125px'})`}
                className={`${classes.content} ${classes.drawerTransition} ${
                  timeSliderOpen ? classes.drawerTransitionShift : ''
                }`}
              >
                {timeSliderOpen ? (
                  <KeyboardArrowDown className={classes.sidebarfabicon} />
                ) : (
                  <>
                    <KeyboardArrowUp className={classes.sidebarfabicon} />
                    <div className={classes.timesliderfab}>
                      <span style={{ paddingRight: 4 }}>Show timeslider</span>
                      <span style={{ paddingLeft: 4, borderLeft: `solid 1px ${theme.palette.common.neutralLight}` }}>
                        {timeSliderTime
                          ?.toLocaleDateString('en-AU', {
                            weekday: 'short',
                            day: '2-digit',
                            month: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            // @ts-ignore
                            hourCycle: 'h23',
                          })
                          .replace(/,/g, '') ?? ''}
                      </span>
                    </div>
                  </>
                )}
              </FloatingMapButton>
              {selectedRasterLayer && (
                <MapOverlay
                  left="default"
                  opacity={1.0}
                  top="20px"
                  style={{
                    right: '20px',
                    overflowX: 'auto',
                    whiteSpace: 'nowrap',
                    filter: `drop-shadow(0px 2px 4px ${theme.palette.common.neutral})`,
                  }}
                >
                  <Legend layer={selectedRasterLayer} />
                </MapOverlay>
              )}
            </Map>
          </div>
          <div
            className={classes.panel}
            style={{ position: 'relative', top: timeSliderOpen ? 'auto' : '100%', height: timeSliderOpen ? 'auto' : 0 }}
          >
            <Timeslider layers={selectedRasterLayerList} onTimeChange={handleTimeChange} />
          </div>
          <div
            className={classes.panel}
            style={{ position: 'relative', top: panelOpen ? 'auto' : '100%', height: panelOpen ? 'auto' : 0 }}
          >
            <DetailsPanel
              isOpen={panelOpen}
              onClose={() => updatePanel(false)}
              onResize={() => updatePanel(true)}
              coords={clickCoords}
              selectedDate={timeSliderTime}
              identifyData={clickDetails}
              weatherStationId={selectedWeatherStation ?? undefined}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default FDVViewer;
