/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from 'react';
import { makeStyles, createStyles, useTheme, withStyles } from '@material-ui/core/styles';
import { Theme, SvgIcon, Typography, Tooltip } from '@material-ui/core';
import { PlayArrow, Pause } from '@material-ui/icons';
import { LayerManager } from 'models';
import { usePrevious } from 'hooks';
import { DateTime } from 'luxon';

// Event Handler Helpers
import startDraggingSliderHelper from './timeslider-subcomponents/event_handlers/startDraggingSlider';
import dragSliderHelper from './timeslider-subcomponents/event_handlers/dragSlider';
import stopDraggingSliderHelper from './timeslider-subcomponents/event_handlers/stopDraggingSlider';
import nextTimeStepHelper from './timeslider-subcomponents/event_handlers/nextTimeStep';

// Function helpers
import calcTickSizeHelper from './timeslider-subcomponents/helper_functions/calcTickSize';
import searchEarliestTimeHelper from './timeslider-subcomponents/helper_functions/searchEarliestTime';
import getLatestTimeHelper from './timeslider-subcomponents/helper_functions/getLatestTime';
import normaliseDateHelper from './timeslider-subcomponents/helper_functions/normaliseDate';
import getDateFromMarginOffsetHelper from './timeslider-subcomponents/helper_functions/getDateFromMarginOffset';
import startOfDayHelper from './timeslider-subcomponents/helper_functions/startOfDay';

// The number of forecast days to cater for
// Note: This should probably be automated more, and defined based on the 'latestDate'
// Currently the only hold back is that it's required in a few of the styles
// so the CSS will need to be updated first.
const forecastDaysOtherLayer = [0, 1, 2, 3, 4, 5, 6, 7];
const forecastDaysDailyLayer = [0, 1, 2, 3, 4, 5, 6];

// Constants used to properly size the the Tabs and the distances between them.
const tabWidth = 12;
const tabHeight = 2 * tabWidth;
const tabBorderRadius = tabWidth / 4;
const tabSpacing = tabWidth * 0.4;

const activeTabWidth = tabWidth;
const activeTabHeight = tabHeight * 1.72;
const activeTabBorderRadius = activeTabWidth / 4;

const tabActiveTextContainerMarginLeft = -70 + activeTabWidth / 2;

/**
 * 3600000: The number of milliseconds in an hour.
 *
 */
const hourInMs = 3600000;

// How often the red 'time now' bar should update and move.
// Should be slow enough to now cause perforamnce issues
// but fast enough that the line isn't noticed to be out of place.
// 2 minutes appears to do this well.
const CURRENT_TIME_UPDATE_INTERVAL = 2 * 60 * 1000; // 2 minutes

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
      borderTop: `1px solid ${theme.palette.common.neutralXLight}`,
      color: theme.palette.common.white,
      height: 120,
      outline: '0px',
    },
    actionIcon: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: `1px solid ${theme.palette.common.neutralXLight}`,
      fontSize: '40px',
      borderRadius: '40px',
      margin: theme.spacing(0.5),
      padding: theme.spacing(0.25),
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.common.neutralXXLight,
      },
    },
    timezoneText: {
      color: theme.palette.common.neutralDark,
      fontWeight: 'bold',
    },
    leftSection: {
      padding: theme.spacing(0),
    },
    controlsSection: {
      padding: theme.spacing(1),
      borderBottom: `solid 1px ${theme.palette.common.neutralXLight}`,
    },
    timeZoneSection: {
      padding: theme.spacing(1),
    },
    sliderSection: {
      padding: `${theme.spacing(0)}px ${theme.spacing(2)}px`,
      placeItems: 'center',
      overflowX: 'auto',
      overflowY: 'hidden',
      display: 'grid',
      height: '100%',
      borderLeft: `1px solid ${theme.palette.common.neutralXLight}`,
      position: 'relative',
    },
    sliderSectionDays: {
      display: 'grid',
      height: '100%',
      gridTemplateColumns: `repeat(${forecastDaysOtherLayer.length}, 1fr)`,
      cursor: 'pointer',
      userSelect: 'none',
    },
    activeTab: {
      position: 'absolute',
      left: theme.spacing(2),
      display: 'grid',
      gridAutoFlow: 'column',
      zIndex: 10,
      height: '100%',
      alignContent: 'center',
      pointerEvents: 'none',
      top: 0,
    },
    daySegment: {
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      minWidth: tabWidth * 24 + tabSpacing * 23,
      borderLeft: `2px dashed ${theme.palette.common.neutralLight}`,
      marginLeft: tabSpacing,
    },
    dayText: {
      color: theme.palette.common.neutralDark,
      fontWeight: 'bold',
      margin: theme.spacing(0.5),
    },
    timeText: {
      color: theme.palette.common.white,
      fontWeight: 'bold',
      margin: `0px ${theme.spacing(0.5)}px`,
    },
    tabsSegment: {
      position: 'absolute',
      display: 'grid',
      gridAutoFlow: 'column',
      transform: `translateX(-${tabWidth / 2 + (tabSpacing + 1)}px)`,
      zIndex: 5,
    },
    tabNormal: {
      backgroundColor: theme.palette.common.neutralLight,
      borderRadius: tabBorderRadius,
      marginLeft: tabSpacing,
      height: tabHeight,
      cursor: 'pointer',
      '&:focus': {
        outline: '0px',
      },
    },
    tabDark: {
      backgroundColor: theme.palette.common.neutralDark,
      borderRadius: tabBorderRadius,
      marginLeft: tabSpacing,
      height: tabHeight,
      cursor: 'pointer',
      '&:focus': {
        outline: '0px',
      },
    },
    tabInactive: {
      // wasn't a noticable difference from neutral, neutralXXLight not noticeable from white
      backgroundColor: '#EAEDEE', // theme.palette.common.neutralXLight,
      borderRadius: tabBorderRadius,
      marginLeft: tabSpacing,
      height: tabHeight,
      cursor: 'pointer',
      '&:focus': {
        outline: '0px',
      },
    },
    tabActive: {
      backgroundColor: theme.palette.common.black,
      borderRadius: activeTabBorderRadius,
      marginLeft: tabSpacing,
      height: activeTabHeight,
      width: activeTabWidth,
      cursor: 'pointer',
      '&:focus': {
        outline: '0px',
      },
      transform: `translateX(-${tabSpacing + 0.2}px)`,
    },
    tabActiveTextContainer: {
      backgroundColor: theme.palette.common.black,
      borderRadius: 8,
      width: 140,
      height: 'min-content',
      marginLeft: tabActiveTextContainerMarginLeft,
      marginTop: -48,
    },
    dottedLineSegment: {
      display: 'grid',
      gridTemplateRows: '1fr 1fr',
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    halfLineSegment: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
    },
    quarterDaySegment: {
      borderLeft: `2px dashed ${theme.palette.common.neutralLight}`,
    },
  }),
);

const CustomTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(18),
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    borderRadius: 12,
  },
}))(Tooltip);

const rangeSvg = (
  <>
    <rect x="4" y="5" width="3" height="14" fill="black" />
    <rect x="17" y="5" width="3" height="14" fill="black" />
    <rect x="10" y="8" width="4" height="8" fill="black" />
  </>
);

const times1Path = (
  <path
    d="M14.2674 20V11.6H12.9114C12.8634 11.92 12.7634 12.188 12.6114 12.404C12.4594 12.62 12.2714 12.796 12.0474 12.932C11.8314 13.06 11.5834 13.152 11.3034 13.208C11.0314 13.256 10.7474 13.276 10.4514 13.268V14.552H12.5634V20H14.2674ZM18.2233 16.736L15.9913 20H17.8993L19.1953 18.044L20.4913 20H22.4353L20.1433 16.7L22.1833 13.796H20.2993L19.2193 15.416L18.1273 13.796H16.1833L18.2233 16.736Z"
    fill="black"
    style={{ transform: 'scale(1.5) translate(-5px, -5px)' }}
  />
);

const times2Path = (
  <path
    d="M10.0074 14.828H11.6394C11.6394 14.604 11.6594 14.38 11.6994 14.156C11.7474 13.924 11.8234 13.716 11.9274 13.532C12.0314 13.34 12.1674 13.188 12.3354 13.076C12.5114 12.956 12.7234 12.896 12.9714 12.896C13.3394 12.896 13.6394 13.012 13.8714 13.244C14.1114 13.468 14.2314 13.784 14.2314 14.192C14.2314 14.448 14.1714 14.676 14.0514 14.876C13.9394 15.076 13.7954 15.256 13.6194 15.416C13.4514 15.576 13.2634 15.724 13.0554 15.86C12.8474 15.988 12.6514 16.116 12.4674 16.244C12.1074 16.492 11.7634 16.736 11.4354 16.976C11.1154 17.216 10.8354 17.48 10.5954 17.768C10.3554 18.048 10.1634 18.368 10.0194 18.728C9.88336 19.088 9.81536 19.512 9.81536 20H15.9834V18.536H12.0114C12.2194 18.248 12.4594 17.996 12.7314 17.78C13.0034 17.564 13.2834 17.364 13.5714 17.18C13.8594 16.988 14.1434 16.796 14.4234 16.604C14.7114 16.412 14.9674 16.2 15.1914 15.968C15.4154 15.728 15.5954 15.456 15.7314 15.152C15.8674 14.848 15.9354 14.484 15.9354 14.06C15.9354 13.652 15.8554 13.284 15.6954 12.956C15.5434 12.628 15.3354 12.352 15.0714 12.128C14.8074 11.904 14.4994 11.732 14.1474 11.612C13.8034 11.492 13.4394 11.432 13.0554 11.432C12.5514 11.432 12.1034 11.52 11.7114 11.696C11.3274 11.864 11.0074 12.104 10.7514 12.416C10.4954 12.72 10.3034 13.08 10.1754 13.496C10.0474 13.904 9.99136 14.348 10.0074 14.828ZM18.2233 16.736L15.9913 20H17.8993L19.1953 18.044L20.4913 20H22.4353L20.1433 16.7L22.1833 13.796H20.2993L19.2193 15.416L18.1273 13.796H16.1833L18.2233 16.736Z"
    fill="black"
    style={{ transform: 'scale(1.5) translate(-5px, -5px)' }}
  />
);

const times3Path = (
  <path
    d="M12.2994 14.996V16.196C12.5074 16.196 12.7234 16.204 12.9474 16.22C13.1794 16.228 13.3914 16.272 13.5834 16.352C13.7754 16.424 13.9314 16.544 14.0514 16.712C14.1794 16.88 14.2434 17.124 14.2434 17.444C14.2434 17.852 14.1114 18.176 13.8474 18.416C13.5834 18.648 13.2594 18.764 12.8754 18.764C12.6274 18.764 12.4114 18.72 12.2274 18.632C12.0514 18.544 11.9034 18.428 11.7834 18.284C11.6634 18.132 11.5714 17.956 11.5074 17.756C11.4434 17.548 11.4074 17.332 11.3994 17.108H9.77936C9.77136 17.596 9.83936 18.028 9.98336 18.404C10.1354 18.78 10.3474 19.1 10.6194 19.364C10.8914 19.62 11.2194 19.816 11.6034 19.952C11.9954 20.088 12.4274 20.156 12.8994 20.156C13.3074 20.156 13.6994 20.096 14.0754 19.976C14.4514 19.856 14.7834 19.68 15.0714 19.448C15.3594 19.216 15.5874 18.928 15.7554 18.584C15.9314 18.24 16.0194 17.848 16.0194 17.408C16.0194 16.928 15.8874 16.516 15.6234 16.172C15.3594 15.828 14.9954 15.604 14.5314 15.5V15.476C14.9234 15.364 15.2154 15.152 15.4074 14.84C15.6074 14.528 15.7074 14.168 15.7074 13.76C15.7074 13.384 15.6234 13.052 15.4554 12.764C15.2874 12.476 15.0674 12.232 14.7954 12.032C14.5314 11.832 14.2314 11.684 13.8954 11.588C13.5594 11.484 13.2234 11.432 12.8874 11.432C12.4554 11.432 12.0634 11.504 11.7114 11.648C11.3594 11.784 11.0554 11.98 10.7994 12.236C10.5514 12.492 10.3554 12.8 10.2114 13.16C10.0754 13.512 9.99936 13.904 9.98336 14.336H11.6034C11.5954 13.904 11.6994 13.548 11.9154 13.268C12.1394 12.98 12.4674 12.836 12.8994 12.836C13.2114 12.836 13.4874 12.932 13.7274 13.124C13.9674 13.316 14.0874 13.592 14.0874 13.952C14.0874 14.192 14.0274 14.384 13.9074 14.528C13.7954 14.672 13.6474 14.784 13.4634 14.864C13.2874 14.936 13.0954 14.98 12.8874 14.996C12.6794 15.012 12.4834 15.012 12.2994 14.996ZM18.2233 16.736L15.9913 20H17.8993L19.1953 18.044L20.4913 20H22.4353L20.1433 16.7L22.1833 13.796H20.2993L19.2193 15.416L18.1273 13.796H16.1833L18.2233 16.736Z"
    fill="black"
    style={{ transform: 'scale(1.5) translate(-5px, -5px)' }}
  />
);

interface TabProps {
  isDark?: boolean;
  isInactive?: boolean;
  hours: number;
  isMainTab?: boolean;
  style?: React.CSSProperties;
}

/**
 * A Tab in the timeslider, is of variable length depending on the amount of time it represents.
 * Used to represent an individual time step.
 * Intended usage of variables:
 * When Dark, it represents a new 24-h interval (when not daily)
 * When Inactive (light), it represents a timestep without data.
 *    Usually occurs when padding steps from the start of the day segment to the first step with data.
 * When it represent the timestep of the selected time, it's considered the active tab.
 */
const Tab: React.FunctionComponent<TabProps> = ({ isDark, hours, isMainTab, style, isInactive }) => {
  const classes = useStyles();
  if (isMainTab) return <div className={classes.tabActive} style={style} />;
  // Tab width is calculated using the base tabWidth and tabSpacing variables
  // For a single hour, it's just 1 tabwidth, however for every hour after this
  // the width must also be wide enough to cover the space that would usually
  // have been between the hourly tabs, this keeps the full day in line.
  const generalStyles = { width: hours * tabWidth + (hours - 1) * tabSpacing, ...style };

  if (isDark) return <div className={classes.tabDark} style={generalStyles} />;

  if (isInactive) return <div className={classes.tabInactive} style={generalStyles} />;

  return <div className={classes.tabNormal} style={generalStyles} />;
};

interface DaySegmentProps {
  hoursPerStep: number;
  idx: number;
  day: Date;
  earliestTime: Date;
  latestTime: Date;
  offsetFor24HourLayers: number;
}

/**
 * An entire day is represented by one of these DaySegments/
 * The timeslider only displays full segments, and each segment will handle how the tabs should look.
 * Builds 24 / hoursPerStep number of tabs,
 *  so for a daily layer, there will only be 1 tab, however
 *  for an hourly layer, there will be 24 tabs, the first being 'Dark'
 *  to represent the start of the day.
 */
const DaySegment: React.FunctionComponent<DaySegmentProps> = ({
  hoursPerStep,
  day,
  earliestTime,
  latestTime,
  offsetFor24HourLayers,
}) => {
  const classes = useStyles();

  const startOfDay = new Date(day);
  startOfDay.setHours(0, 0, 0, 0);

  const tabCount = Math.floor(24 / hoursPerStep);
  const tabs = [];
  const startOfDayMs = startOfDay.getTime();
  const earliestTimeMs = earliestTime.getTime();
  const latestTimeMs = latestTime.getTime();
  let tabTimeMs = 0;
  for (let i = 0; i < tabCount; i += 1) {
    // This 'offsetFor24HourLayers' doesn't make sense in the context of multiple timesteps.
    // This daysegment needs to be able to handle multiple layers better.
    tabTimeMs = startOfDayMs + hourInMs * i * hoursPerStep + offsetFor24HourLayers * hourInMs * +(hoursPerStep === 24);

    tabs.push(
      <Tab
        key={i}
        isDark={i === 0 && hoursPerStep < 24}
        hours={hoursPerStep}
        isInactive={tabTimeMs < earliestTimeMs || tabTimeMs > latestTimeMs}
      />,
    );
  }

  const extraTabStyles: React.CSSProperties = {};

  if (hoursPerStep === 3) {
    // 3-Hourly layers are special as they may not start at 00 in the day and
    // refer to their literal range
    // Seperate to daily layers which may not start at 00 but still refer to
    // the day starting at 00.
    let offsetHours = ((earliestTimeMs - startOfDayMs) / hourInMs) % hoursPerStep;
    // If offsetHours +1 or +2, we want the first tick to start before the start
    // of the day
    if (offsetHours > 0) offsetHours -= hoursPerStep;
    extraTabStyles.transform = `translateX(${
      offsetHours * tabWidth + offsetHours * (hoursPerStep - 1) * tabSpacing
    }px)`;
  }

  return (
    <div className={classes.daySegment}>
      <div className={classes.tabsSegment} style={extraTabStyles}>
        {tabs}
      </div>
      <div className={classes.dottedLineSegment}>
        <div>
          <Typography variant="subtitle2" className={classes.dayText} align="center">
            {day.toLocaleDateString('en-AU', { weekday: 'short', day: '2-digit', month: '2-digit' }).replace(/,/g, '')}
          </Typography>
        </div>
        <div className={classes.halfLineSegment}>
          <div />
          <div className={classes.quarterDaySegment} />
          <div className={classes.quarterDaySegment} style={{ marginLeft: 1.6 }} />
          <div className={classes.quarterDaySegment} style={{ marginLeft: 3.2 }} />
        </div>
      </div>
    </div>
  );
};

/**
 * Represents the dynamicly calculated normalised date while the
 * TimeSlider active tab is being dragged.
 * As dragging is handled as a side effect, we don't want a full redraw to occur or a state update.
 * This date is specifically only used as a comparison to the dragged point raw time to work out if in fact
 * the slider has crossed a new normalised time. When it has, only then is the timeslider state updated.
 *
 * This should be safe to define here (as opposed to a state variable) as only 1 timeslider can ever
 * be dragged at once and this value is only used during the drag action.
 */
let dragDate: Date | null = null;

let initialIntervalDate = new Date();

interface TimesliderProps {
  /**
   * A list of layers for the timeslider to adjust to and return time values for.
   */
  layers?: LayerManager.Layer[];
  /**
   * A callback function returning the raw selected date along with a Dictionary of each
   * layer's id as a key and their value being the specific date to be queried.
   * For example, a hourly and daily layer, selecting 5pm would return 5pm and 2am respectively,
   * with 2am being the timestep for that daily layer which covers the raw selected time.
   */
  onTimeChange?: (date: Date | null | undefined, layerDates: Record<string, Date | null>, initial: boolean) => void;
  /**
   * The number of active maps (or 'views').
   * It's required for when the list of layers don't change, but a view is added requiring the
   * timeslider to normalise and call the callback if changed.
   *
   * Note: This was added when the list of layers didn't change as the compare tool added/removed views.
   *       Now as a view is added/removed, that layer is removed from the list, likely causing the same
   *       desired effect, making this value obsolete.
   *       This should be properly checked and tested, but for now causes no harm.
   */
  viewCount?: number;
}

const Timeslider: React.FunctionComponent<TimesliderProps> = ({ layers, onTimeChange, viewCount }) => {
  const classes = useStyles();
  const theme = useTheme();

  const prevLayers = usePrevious(layers);
  const prevViewCount = usePrevious(viewCount);

  const [now, setNowDate] = useState(new Date());
  const timeOffset = now.getTimezoneOffset();

  const setNow = () => setNowDate(new Date());

  // This useEffect kicks off an interval that keeps the red 'time now' bar in the correct place.
  useEffect(() => {
    const updateTime = setInterval(setNow, CURRENT_TIME_UPDATE_INTERVAL);
    return () => {
      clearInterval(updateTime);
    };
  });

  // Calculate tick size (need to get the minimum of the assigned layers)
  const hoursPerStep = calcTickSizeHelper({ layers });

  const forecastDays = hoursPerStep === 24 ? forecastDaysDailyLayer : forecastDaysOtherLayer;

  const offsetForLayer: Partial<Record<LayerManager.Layer.LayerIds, number>> = {};

  // Search for the earliest time from the timesteps out of all the layers provided.
  const [tempEarliestTime, tempOffsetKeysList, tempOffsetValuesList] = searchEarliestTimeHelper({ layers });

  const earliestTime = tempEarliestTime;
  // Apply the offset layers assignment
  for (let i = 0; i < tempOffsetKeysList.length; i += 1) {
    const currKey = tempOffsetKeysList[i];
    const currVal = tempOffsetValuesList[i];
    offsetForLayer[currKey] = currVal;
  }

  // Get the latest time so we know where the dark ticks need to stop
  const latestTime = getLatestTimeHelper({ layers });

  // Get the latest start time
  // is just start time when 1 layer, when multiple it's the latest of all start times
  const latestStart = LayerManager.getLatestLayerStartDate(layers);

  // Calculate current Time tab location
  const startOfDay = earliestTime && new Date(earliestTime);

  const offsetFor24HourLayers = ((earliestTime?.getHours() ?? 0) + (earliestTime?.getMinutes() ?? 0) / 60) % 24;
  const offsetFor3HourLayers = ((earliestTime?.getHours() ?? 0) + (earliestTime?.getMinutes() ?? 0) / 60) % 3;

  // Mouse UI elements
  const [mouseDragPosition, setMouseDragPosition] = useState<number | null>(null);
  const sliderTabRef = useRef<HTMLDivElement>(null);
  const sliderSection = useRef<HTMLDivElement>(null);
  const sliderTabTextRef = useRef<HTMLDivElement>(null);
  const [currentTime, setStateCurrentTime] = useState(LayerManager.getLatestLayerStartDate(layers));

  // <DAYLIGHT SAVINGS FIX FOR 24-HOUR>

  // Since most daily layers start at 14 or 15 UTC (00 or 01 AEST not sure about daylight savings)
  // other timezones such as Perth will actually start at 22 or 23 the day before.
  // To avoid this, if the earliest time is very late in the day it's likely it was 'meant' for the next day.
  // To make sure no hourly layer starts at 22 or 23 (this way we don't skip data points)
  // we check to make sure hours per step is at least greater than this '2 hour' window where we increment the day
  // ADST Visual Offset - Contains the number of milliseconds to offset
  //  the displayed times by.
  let ADSTVisOffset = 0;
  const SYDTime = DateTime.local().setZone('Australia/Sydney');
  if (startOfDay && startOfDayHelper({ startOfDay, hoursPerStep }) && SYDTime.isInDST) {
    // ! DAYLIGHT SAVINGS OVERRIDE (NOT FACTORED FOR 24 HOUR TIMESTEPS)
    // Adds a day in milliseconds
    ADSTVisOffset = 86400000;
  }

  if (startOfDay) startOfDay.setHours(0, 0, 0, 0);

  // </DAYLIGHT SAVINGS FIX FOR 24-HOUR>

  // Will scroll the timeslider to the active tab
  // and attempt to place it in the center of the timeslider.
  const scrollToTab = async () => {
    setTimeout(() => {
      if (sliderSection.current && sliderTabRef.current) {
        const marginLeft = +sliderTabRef.current.style.marginLeft.substr(
          0,
          sliderTabRef.current.style.marginLeft.length - 2,
        );
        sliderSection.current.scrollTo({
          top: 0,
          left: marginLeft - sliderSection.current.offsetWidth / 2,
          behavior: 'smooth',
        });
      }
    }, 10);
  };

  const hoursFromStart =
    currentTime && startOfDay
      ? Math.abs(Math.floor((currentTime.getTime() - startOfDay.getTime()) / hourInMs))
      : undefined;

  /**
   *  The selected tab is the only one not attached to a DaySegment
   *  It represents the selected time and is required to be draggable.
   *  It's not attached to a day segment as it must be able to be dragged across the entire timeslider.
   *  It's simply drawn on top of the tabs below.
   *  Is undefined when not in a positition to be drawn,
   *  ie: before the first day, or after the last day.
   */
  const activeTab =
    currentTime != null &&
    hoursFromStart != null &&
    startOfDay != null &&
    currentTime.getTime() >= startOfDay.getTime() &&
    currentTime.getTime() < startOfDay.getTime() + forecastDays.length * 24 * hourInMs ? (
      <Tab isMainTab hours={NaN} />
    ) : undefined;

  /**
   * Will set the timesliders time to the given time. Then call the 'onTimeChange' callback with the new dates.
   * @param date Will update the timeslider state to this Date
   * @param initial Whether this represents the initial time update. This will occur when the timeslider is first built and when a new layer is added.
   */
  const setCurrentTime = (date?: Date | null, initial?: boolean) => {
    setStateCurrentTime(date);
    const layerTimes: Record<string, Date | null> = {};
    const dateOfTimeStamp = date ? new Date(date.getTime()) : null;

    if (date)
      layers?.forEach((layer) => {
        layerTimes[layer.id] = LayerManager.getStepTime(layers, layer.id, date);
      });

    if (onTimeChange) onTimeChange(dateOfTimeStamp, layerTimes, initial ?? false);
  };

  /**
   * Will normalise a provided date. In the context of this timeslider this means that
   * for any raw date/time, the returned date will match a selectable time.
   * In other words will will convert a raw date to the date that matches any of the drawn tabs on the slider.
   * @param date The date to normalise
   * @returns A normalised date
   */
  const normaliseDate = (date: Date): Date => {
    return normaliseDateHelper({
      date,
      hoursPerStep,
      startOfDay,
      hourInMs,
      offsetFor3HourLayers,
    });
  };

  /**
   * A convienience function.
   * Will first normalise the given date (see 'normaliseDate') if the required values are set.
   * Then will set the current time to this normalised value.
   * @param date The date to normalise then use to set the time
   * @param initial See 'initial' in the function 'setCurrentTime'
   */
  const setAndNormaliseCurrentTime = (date?: Date | null, initial?: boolean) => {
    if (date && hoursPerStep && startOfDay) {
      setCurrentTime(normaliseDate(date), initial);
    } else {
      setCurrentTime(date, initial);
    }
  };

  const [isPlayingInterval, setisPlayingInterval] = useState<null | NodeJS.Timeout>(null);
  const [playbackSpeedIndex, setplaybackSpeedIndex] = useState(0);

  /**
   * Using the units of switches per second (1 switch / X seconds)
   * Should be specifically crafted to make use of the
   * '1x', '2x', '3x' SVGs defined above.
   * Should this need to be changed then the icons should be reviewed.
   * See 'playBackIcons'.
   */
  const playBackSpeeds = [1 / 6, 2 / 6, 3 / 6];

  /**
   * The function to be called at the playback Speed interval.
   * Each call of this function will increment the time to the next selectable time.
   * If it gets to the last time (see 'latestTime') then it will return to
   * the 'earliestTime', effectively looping the playback.
   * It will also scroll to ensure the tab stays in the middle.
   */
  const intervalFunction = () => {
    if (currentTime && latestTime && earliestTime && hoursPerStep) {
      const newDate = new Date(initialIntervalDate.getTime() + hoursPerStep * hourInMs);

      if (newDate <= normaliseDate(latestTime)) {
        setCurrentTime(newDate);
        initialIntervalDate = newDate;
      } else {
        setAndNormaliseCurrentTime(earliestTime);
        initialIntervalDate = normaliseDate(earliestTime);
      }
    }

    scrollToTab();
  };

  /**
   * When the playback speeds are toggled, the old playback needs to be stopped and started again.
   * If it isn't playing, it will start playing if this is called.
   * @param newPlaybackSpeed The new desired speed.
   */
  const restartInterval = (newPlaybackSpeed?: number) => {
    if (isPlayingInterval != null) {
      clearInterval(isPlayingInterval);
    }
    if (currentTime) {
      initialIntervalDate = new Date(currentTime);
      setisPlayingInterval(
        setInterval(intervalFunction, 1000 / (newPlaybackSpeed ?? playBackSpeeds[playbackSpeedIndex])),
      );
    }
  };

  /**
   * The onClick callback for the playback speed icons.
   * If it's playing it will also restart the interval.
   * This toggle will loop over the available values in 'playBackSpeeds'.
   */
  const toggleSpeed = () => {
    const newPlaybackIndex = (playbackSpeedIndex + 1) % playBackSpeeds.length;
    setplaybackSpeedIndex(newPlaybackIndex);
    if (isPlayingInterval != null) restartInterval(playBackSpeeds[newPlaybackIndex]);
  };

  const times1Svg = (
    <SvgIcon viewBox="0 0 32 32" className={classes.actionIcon} onClick={toggleSpeed}>
      {times1Path}
    </SvgIcon>
  );

  const times2Svg = (
    <SvgIcon viewBox="0 0 32 32" className={classes.actionIcon} onClick={toggleSpeed}>
      {times2Path}
    </SvgIcon>
  );

  const times3Svg = (
    <SvgIcon viewBox="0 0 32 32" className={classes.actionIcon} onClick={toggleSpeed}>
      {times3Path}
    </SvgIcon>
  );

  /**
   * The list of icons to be used for each playback speed.
   * See 'playbackSpeeds'.
   */
  const playBackIcons = [times1Svg, times2Svg, times3Svg];

  /**
   * Text that displays the users current timezone.
   *
   * Standards List: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
   */
  const timeZoneText = `UTC ${timeOffset < 0 ? '+' : '-'}${`${Math.abs(Math.floor(timeOffset / -60))}`.padStart(
    2,
    '0',
  )}:${`${Math.abs(timeOffset) % -60}`.padStart(2, '0')}`;

  /**
   * Will start the playback at the current time.
   */
  const play = () => {
    if (currentTime) {
      initialIntervalDate = new Date(currentTime);
      restartInterval();
    }
  };

  /**
   * Will stop the playback
   */
  const stop = () => {
    if (isPlayingInterval) clearInterval(isPlayingInterval);
    setisPlayingInterval(null);
  };

  /**
   * List of Day segments to be rendered.
   *
   * NOTE: ADSTVisOffset added to offset the display time for the days be
   * 24 hrs.
   */
  const days =
    hoursPerStep != null && startOfDay != null && earliestTime != null && latestTime != null
      ? forecastDays.map((val) => (
          <DaySegment
            key={val}
            idx={val}
            hoursPerStep={hoursPerStep}
            earliestTime={new Date(earliestTime.getTime() + ADSTVisOffset)}
            latestTime={new Date(latestTime.getTime() + ADSTVisOffset)}
            day={new Date(startOfDay.getTime() + ADSTVisOffset + val * (hourInMs * 24))}
            offsetFor24HourLayers={offsetFor24HourLayers}
          />
        ))
      : [];

  // This useEffect handles the adding/removing of layers from the layer list.
  useEffect(() => {
    // First find out what has been added and what has been removed.
    // Both added/removed are added to this 'missing' list.
    const missing: string[] = [];
    prevLayers?.forEach((pl) => {
      if ((layers ?? []).findIndex((l) => pl.id === l.id) === -1) missing.push(pl.id);
    });
    layers?.forEach((l) => {
      if ((prevLayers ?? [])?.findIndex((pl) => pl.id === l.id) === -1) missing.push(l.id);
    });

    if (missing.length > 0) {
      // layers.length > 1 will be for the compare tool
      if (currentTime && layers && layers.length > 1) {
        const latestLayerStart = LayerManager.getLatestLayerStartDate(layers);
        // when changing data source in compare tool select a
        // startup time where all maps have some data (so map
        // does not appear blank)
        if (latestLayerStart && currentTime < latestLayerStart) {
          setTimeout(() => setAndNormaliseCurrentTime(latestLayerStart, true), 50);
        } else {
          setTimeout(() => setAndNormaliseCurrentTime(currentTime, true), 50);
        }
      } else if (latestStart != null) {
        setTimeout(() => setAndNormaliseCurrentTime(latestStart, true), 50);
      } else {
        setTimeout(() => setAndNormaliseCurrentTime(currentTime, true), 50);
      }
      stop();
    }
  }, [layers]);

  // Handler for when the number of maps (views) have changed
  useEffect(() => {
    if (viewCount && prevViewCount) {
      // pick up scenario when 1 view has been added or removed
      // if so set time to latestLayerStart so all maps show data
      if (Math.abs(prevViewCount - viewCount) === 1) {
        const latestLayerStart = LayerManager.getLatestLayerStartDate(layers);
        setTimeout(() => setAndNormaliseCurrentTime(latestLayerStart, true), 50);
      }
    }
  }, [viewCount]);

  /**
   * Used for when the slider is being dragged to calculate the date from
   * the specific pixel location of the slider. This is then normalised to
   * figure out what time is selected by this.
   * @param offset Number of pixels from the left of the timeslider.
   * @returns The date represented by the provided offset.
   */
  const getDateFromMarginOffset = (offset: number): Date | null => {
    return getDateFromMarginOffsetHelper({
      offset,
      hoursPerStep,
      tabSpacing,
      activeTabWidth,
      tabWidth,
      offsetFor3HourLayers,
      hourInMs,
      startOfDay,
    });
  };

  /**
   * The CSS 'left margin' value in pixels describing where to draw the
   * activeTab and the time now display.
   * When being dragged, it's set to the drag position.
   */
  let sliderTabMarginLeft = hoursFromStart != null ? hoursFromStart * tabWidth + hoursFromStart * tabSpacing : -250;

  if (mouseDragPosition) sliderTabMarginLeft = mouseDragPosition;

  /**
   * Controls the left margin of time now display.
   * It preferred to keep it in the middle of the activeTab, but will shift right
   * to prevent it from sitting off the left edge of the timeslider.
   * See 'sliderTabMarginLeft'.
   */
  const sliderTabTextMarginLeft =
    sliderTabMarginLeft < 0
      ? sliderTabMarginLeft
      : Math.max(sliderTabMarginLeft, Math.abs(tabActiveTextContainerMarginLeft));

  /**
   * Current time minus the first timestep in milliseconds.
   * See 'currentTimeMarginLeft' for usage.
   *
   * NOTE:
   * ADSTVisOffset is subtracted from the offset amount since the 'start' of the
   * red line offset is from the 'start of the current day'.
   * As without this subtraction, the red line (which shows the current time)
   * will be a 24 hrs ahead.
   */
  const timeNowHoursFromStart =
    now && startOfDay ? (now.getTime() - startOfDay.getTime()) / hourInMs - ADSTVisOffset / hourInMs : undefined;

  /**
   * Used to calculate the position of the red vertical 'time now' line.
   */
  const currentTimeMarginLeft = timeNowHoursFromStart
    ? timeNowHoursFromStart * tabWidth +
      (timeNowHoursFromStart - 1 >= 0 ? timeNowHoursFromStart - 1 : 0) * tabSpacing +
      tabSpacing +
      tabWidth / 2
    : -250;

  /**
   * Mouse Down event handler for initialising the slider drag logic.
   */
  const startDraggingSlider = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    startDraggingSliderHelper({ event, activeTabWidth, isPlayingInterval, setMouseDragPosition });
  };

  /**
   * Handles with slider drag event update.
   * It will calculate the hover date from the pixel offests and using 'dragDate'
   * when it detects a new normalised date has been crossed. Then the timeslider
   * state and date will update.
   * This allows the map to render as the slider is being dragged.
   */
  const dragSlider = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    let newMargin = null;
    let newMarginText = null;
    let newDate = null;
    let newDateCheck = false;

    [newMargin, newMarginText, newDate, newDateCheck] = dragSliderHelper({
      event,
      sliderTabRef,
      sliderTabTextRef,
      activeTabWidth,
      tabActiveTextContainerMarginLeft,
      getDateFromMarginOffset,
      dragDate,
      currentTime,
    });

    // Check the slider
    if (sliderTabRef.current && sliderTabTextRef.current) {
      sliderTabRef.current.style.marginLeft = `${newMargin}px`;
      sliderTabTextRef.current.style.marginLeft = `${newMarginText}px`;
    }

    if (newDateCheck) {
      dragDate = newDate;
      setCurrentTime(newDate);
    }
  };

  /**
   * Handles the mouse up event and effectively stops the slider from being dragged
   * and update the timeslider state to be the final drag normalised date.
   */
  const stopDragingSlider = () => {
    let sliderTabRefCurrentFlag = false;
    let marginLeftNotNaNFlag = false;
    let isPlayingIntervalFlag = false;
    let marginLeftReturnVar = null;

    [sliderTabRefCurrentFlag, marginLeftNotNaNFlag, isPlayingIntervalFlag, marginLeftReturnVar] =
      stopDraggingSliderHelper({ sliderTabRef, isPlayingInterval });

    if (sliderTabRefCurrentFlag && marginLeftNotNaNFlag && marginLeftReturnVar != null) {
      setCurrentTime(getDateFromMarginOffset(marginLeftReturnVar));
    }
    dragDate = null;
    setMouseDragPosition(null);
    // restart interval when dragging stops
    if (isPlayingIntervalFlag) restartInterval();
  };

  /**
   * When called, the next timeslider will move to the next time step and sroll to it if necessary.
   * Used in the Keyboard event callback when the right arrow is pressed.
   */
  const nextTimeStep = () => {
    let currTimeAndHrPerStepNotNullFlag = false;
    let setCurrentTimeFlag = false;
    let isPlayingIntervalFlag = false;
    let newTime: Date | null = null;

    // Process the request with the helper
    [currTimeAndHrPerStepNotNullFlag, setCurrentTimeFlag, isPlayingIntervalFlag, newTime] = nextTimeStepHelper({
      currentTime,
      hoursPerStep,
      latestTime,
      hourInMs,
      normaliseDate,
      isPlayingInterval,
    });

    // Make the changes based on the response
    if (currTimeAndHrPerStepNotNullFlag) {
      if (setCurrentTimeFlag) {
        setCurrentTime(newTime);
      } else {
        setAndNormaliseCurrentTime(earliestTime);
        if (isPlayingIntervalFlag) restartInterval();
      }
    }
    scrollToTab();
  };

  /**
   * When called, the next timeslider will move to the previous time step and sroll to it if necessary.
   * Used in the Keyboard event callback when the left arrow is pressed.
   */
  const prevTimeStep = () => {
    if (currentTime && hoursPerStep) {
      const newTime = new Date(currentTime.getTime() - hoursPerStep * hourInMs);
      if (earliestTime && newTime >= normaliseDate(earliestTime)) {
        setCurrentTime(newTime);
      } else {
        setAndNormaliseCurrentTime(latestTime);
      }
    }
    scrollToTab();
  };

  // Not included for now as it's beyond scope, but the
  // functionality may be added due to it being in Figma Designs
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const rangeIcon = <SvgIcon className={classes.actionIcon}>{rangeSvg}</SvgIcon>;

  return (
    <div
      className={classes.root}
      onKeyDown={(event) => {
        if (event.key === 'ArrowLeft') {
          prevTimeStep();
        }

        if (event.key === 'ArrowRight') {
          nextTimeStep();
        }
      }}
      role="slider"
      aria-valuenow={currentTime?.getTime() ?? NaN}
      tabIndex={0}
    >
      <div className={classes.leftSection}>
        <div className={classes.controlsSection}>
          {isPlayingInterval != null && (
            <CustomTooltip title="Pause" placement="top">
              <Pause className={classes.actionIcon} onClick={stop} />
            </CustomTooltip>
          )}
          {isPlayingInterval == null && (
            <CustomTooltip title="Play" placement="top">
              <PlayArrow className={classes.actionIcon} onClick={play} />
            </CustomTooltip>
          )}
          <CustomTooltip title="Speed" placement="top">
            {playBackIcons[playbackSpeedIndex]}
          </CustomTooltip>
        </div>
        <div style={{ padding: theme.spacing(1) }}>
          <Typography variant="subtitle2" align="center" className={classes.timezoneText}>
            {timeZoneText}
          </Typography>
        </div>
      </div>
      <div ref={sliderSection} className={classes.sliderSection}>
        <div
          className={classes.sliderSectionDays}
          style={{ gridTemplateColumns: `repeat(${forecastDays.length}, 1fr)` }}
          onMouseDown={(event) => {
            if (event.button === 0) startDraggingSlider(event);
          }}
          onMouseUp={(event) => {
            if (event.button === 0) stopDragingSlider();
          }}
          onMouseMove={(event) => {
            if (mouseDragPosition != null && event.button === 0) {
              dragSlider(event);
            }
          }}
          onMouseLeave={() => {
            if (mouseDragPosition != null) stopDragingSlider();
          }}
        >
          {days}
        </div>
        <div
          ref={sliderTabRef}
          className={classes.activeTab}
          style={{
            marginLeft: sliderTabMarginLeft,
            transition:
              mouseDragPosition == null
                ? `${theme.transitions.create('margin-left', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.standard,
                  })}`
                : '',
          }}
        >
          {activeTab}
        </div>
        <div
          ref={sliderTabTextRef}
          className={classes.activeTab}
          style={{
            marginLeft: sliderTabTextMarginLeft,
            zIndex: 15,
            transition:
              mouseDragPosition == null
                ? `${theme.transitions.create('margin-left', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.standard,
                  })}`
                : '',
          }}
        >
          <div className={classes.tabActiveTextContainer}>
            <Typography variant="subtitle2" className={classes.timeText} align="center">
              {currentTime
                ? new Date(currentTime.getTime() + ADSTVisOffset)
                    .toLocaleDateString('en-AU', {
                      weekday: 'short',
                      day: '2-digit',
                      month: '2-digit',
                      hour: hoursPerStep === 24 ? undefined : '2-digit',
                      minute: hoursPerStep === 24 ? undefined : '2-digit',
                      // @ts-ignore
                      hourCycle: 'h23',
                    })
                    .replace(/,/g, '')
                : 'N/A'}
            </Typography>
          </div>
        </div>
        <div
          className={classes.activeTab}
          style={{
            borderRight: '2px solid red',
            opacity: 0.5,
            marginLeft: currentTimeMarginLeft,
            transition: `${theme.transitions.create('margin-left', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.standard,
            })}`,
          }}
        />
      </div>
    </div>
  );
};

export default Timeslider;
