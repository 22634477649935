interface StartDraggingSliderInterface {
  event: React.MouseEvent<HTMLDivElement, MouseEvent>;
  activeTabWidth: number;
  isPlayingInterval: NodeJS.Timeout | null;
  setMouseDragPosition: React.Dispatch<React.SetStateAction<number | null>>;
}

/**
 * Mouse Down event handler for initialising the slider drag logic.
 * @param event The mouse down event to handle
 * @param activeTabWidth The tabWidth constant from the parent
 * @param isPlayingInterval The isPlayingInterval constant from the parent
 * @param setMouseDragPosition The set function from the `useState` declaration.
 */
function startDraggingSliderHelper({
  event,
  activeTabWidth,
  isPlayingInterval,
  setMouseDragPosition,
}: StartDraggingSliderInterface) {
  setMouseDragPosition(event.clientX - event.currentTarget.getBoundingClientRect().x - activeTabWidth / 2);

  // This will clear the interval if it's playing, but I'm not removing it so I know to restart it when dragging stops.
  if (isPlayingInterval != null) clearInterval(isPlayingInterval);
}

export default startDraggingSliderHelper;
