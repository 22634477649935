interface NormaliseDateInterface {
  date: Date;
  hoursPerStep: number | null;
  startOfDay: Date | null | undefined;
  hourInMs: number;
  offsetFor3HourLayers: number;
}

/**
 * Will normalise a provided date. In the context of this timeslider this
 *  means that for any raw date/time, the returned date will match a selectable
 *  time.
 *
 * In other words will will convert a raw date to the date that matches any of
 *   the drawn tabs on the slider.
 * @param date : The date to attempt to normalise
 * @param hoursPerStep : The number of hours per step
 * @param startOfDay : The timestamp specifying the start of the day
 * @param hourInMs : (constant) 60(min)*60(sec)*1000(ms)
 * @param offsetFor3HourLayers : (no idea lol)
 * @returns the normalised date.
 */
function normaliseDateHelper({
  date,
  hoursPerStep,
  startOfDay,
  hourInMs,
  offsetFor3HourLayers,
}: NormaliseDateInterface) {
  if (hoursPerStep == null || startOfDay == null) return date;

  const hoursPastStartOfDay =
    (date.getTime() - startOfDay.getTime() - hourInMs * offsetFor3HourLayers * +(hoursPerStep === 3)) /
    (hoursPerStep * hourInMs);
  const newDate = new Date(
    startOfDay.getTime() +
      Math.floor(hoursPastStartOfDay) * (hoursPerStep * hourInMs) +
      hourInMs * offsetFor3HourLayers * +(hoursPerStep === 3),
  );
  return newDate;
}

export default normaliseDateHelper;
