import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, Theme } from '@material-ui/core';

import ChartComponent from './Chart';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    padded: {
      padding: theme.spacing(2),
    },
    chartContainer: {
      padding: theme.spacing(2),
      position: 'relative',
    },
    chartTitle: {
      color: theme.palette.common.neutralDark,
      fontSize: 12,
      fontWeight: 600,
      marginBottom: theme.spacing(1),
    },
    legend: {
      position: 'absolute',
      top: theme.spacing(2),
      right: theme.spacing(2),
      display: 'inline-block',
    },
    download: {
      padding: theme.spacing(0.25),
      marginRight: theme.spacing(3),
      color: theme.palette.common.black70,
      '&:hover': {
        backgroundColor: theme.palette.common.neutralXLight,
      },
    },
    legendItem: {
      display: 'inline-block',
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.common.grey,
      marginRight: theme.spacing(4),
      paddingLeft: theme.spacing(1),
    },
  }),
);

interface IProps {
  name: string;
  color: string;
}

const LegendItem: React.FunctionComponent<IProps> = ({ name, color }) => {
  const classes = useStyles();
  return (
    <div className={classes.legendItem} style={{ borderLeft: `2px solid ${color}` }}>
      {name}
    </div>
  );
};

interface ChartDataset {
  data: [Date, number][];
  color?: string;
  label?: string;
  showLine?: boolean;
  pointRadius?: number;
}

interface BigChartProps extends IProps {
  selectedDate: Date | null;
  datasets?: ChartDataset[];
  hideLegend?: boolean;
  timeFormat?: string;
  units?: string;
  min?: Date;
  max?: Date;
  yAxesLabelsCallback?: (
    value: string | number,
    index: number,
    values: string[] | number[],
  ) => string | number | null | undefined;
}

const BigChart: React.FunctionComponent<BigChartProps> = ({
  name,
  color,
  selectedDate,
  datasets,
  hideLegend,
  timeFormat,
  units,
  min,
  max,
  yAxesLabelsCallback,
}) => {
  const classes = useStyles();

  const now = new Date();

  const verticalLines = [{ index: now, color: 'red' }];

  const hasData = datasets && datasets[0] && datasets[0].data.length > 0;

  const earliestDatasetTime = datasets?.reduce<null | Date>((acc, ds) => {
    if (ds.data[0]?.[0] && (!acc || (acc && ds.data[0][0] < acc))) return ds.data[0][0];
    return acc;
  }, null);

  if (selectedDate && earliestDatasetTime && selectedDate >= earliestDatasetTime)
    verticalLines.push({ index: selectedDate, color: 'silver' });

  const downloadForecastAsCSV = () => {
    if (datasets && datasets[0] && datasets[0].data.length > 0) {
      const csvContent = [
        `date, ${name ?? 'value'}${units ? ` (${units})` : ''}`,
        ...datasets[0].data.map((row) => `"${row[0].toISOString()}", ${row[1]}`),
      ].join('\n');
      const link = window.document.createElement('a');
      link.setAttribute('href', `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURI(csvContent)}`);
      link.setAttribute('download', `${name ?? 'forecast'}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className={classes.chartContainer}>
      {!hideLegend && (
        <>
          <div className={classes.chartTitle}>{name}</div>
          <div className={classes.legend}>
            <Button onClick={downloadForecastAsCSV} disabled={!hasData} className={classes.download}>
              Download Forecast
            </Button>
            <LegendItem name={name} color={color} />
            <LegendItem name="Now" color="red" />
            <LegendItem name="Selected" color="silver" />
          </div>
        </>
      )}
      <div style={{ height: 150 }}>
        <ChartComponent
          datasets={(datasets ?? []).map((ds) => ({
            ...ds,
            data: ds.data.map((x) => ({ x: x[0].getTime(), y: x[1] })),
          }))}
          labels={[]}
          color={color}
          verticalLines={verticalLines}
          timeFormat={timeFormat}
          units={units}
          min={min?.getTime()}
          max={max?.getTime()}
          yAxesLabelsCallback={yAxesLabelsCallback}
        />
      </div>
    </div>
  );
};

export default BigChart;
