interface GetDateFromMarginOffsetInterface {
  offset: number;
  startOfDay: Date | null | undefined;
  hoursPerStep: number | null;
  tabSpacing: number;
  activeTabWidth: number;
  tabWidth: number;
  offsetFor3HourLayers: number;
  hourInMs: number;
}

/**
 * Used for when the slider is being dragged to calculate the date from
 * the specific pixel location of the slider. This is then normalised to
 * figure out what time is selected by this.
 * @returns The date represented by the provided offset (or `null`).
 */
function getDateFromMarginOffsetHelper({
  offset,
  startOfDay,
  hoursPerStep,
  tabSpacing,
  activeTabWidth,
  tabWidth,
  offsetFor3HourLayers,
  hourInMs,
}: GetDateFromMarginOffsetInterface) {
  if (startOfDay && hoursPerStep != null) {
    const hFS = Math.floor((offset + tabSpacing / 2 + activeTabWidth / 2) / (tabWidth + tabSpacing));
    const newDate = new Date(
      startOfDay.getTime() +
        Math.floor((hFS - offsetFor3HourLayers * +(hoursPerStep === 3)) / hoursPerStep) * hoursPerStep * hourInMs +
        hourInMs * offsetFor3HourLayers * +(hoursPerStep === 3),
    );
    return newDate;
  }
  return null;
}

export default getDateFromMarginOffsetHelper;
